@import 'css/_variables.scss';
@import 'css/_functions.scss';
@import 'css/mixins/typography.scss';

.container {
  &__icon {
    font-size: 4rem;
    margin-bottom: pxToRem(29px);
    &.--danger {
      color: $danger-color;
    }
    &.--success {
      color: $success-color;
    }
    &.--alert {
      color: $alert-color;
    }
  }
  &__title {
    @include tertiary-text(1.5rem, $black, 500, 2rem);
    margin: 0;
  }
  &__contentMessage {
    @include tertiary-text(0.875rem, $opaque-black, 400, 1.375rem);
    margin: 0;
  }
  &__highlightedNote {
    @include tertiary-text(0.875rem, $opaque-black, 700, 1.375rem);
    margin: 0;

    &__icon {
      color: $warning;
      margin-right: 8px;
    }
  }
  :global {
    .ant-modal-confirm-btns {
      margin-top: pxToRem(32px);
      width: 100%;
      text-align: center;
    }
  }
}
