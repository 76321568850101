@import 'css/mixins';
@import 'css/functions';

.container {
  display: flex;
  flex-direction: column;
  row-gap: 1.3em;
  height: 100%;

  &__header {
    &__title {
      white-space: nowrap;
      align-self: flex-start;
      margin-bottom: 15px;
    }

    &__controls {
      height: pxToRem(48px);
      grid-column: 3/4;
      display: flex;
      align-self: flex-end;
      justify-content: flex-start;
      column-gap: pxToRem(16px);
    }
  }

  &__content {
    background: white;
    padding: 1em;
  }

  &__footer {
    padding-bottom: 1.2em;
    text-align: end;
  }
}
