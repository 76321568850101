@import 'css/mixins';
.modal {
  :global {
    .ant-modal-close-icon {
      @include font-size(22px);
    }
    .ant-modal-title {
      @include font-size(22px);
    }
  }
}
.okButton,
.cancelButton {
  @include font-size(16px);
}
