@import 'css/general_styling';

.stock {
  display: flex;
  align-items: center;
  span {
    /* @include primary-text($title-main, $secondary-color, 700); */
    text-transform: uppercase;
    margin-left: 5px;
  }
}
