@import 'css/general_styling';

.inputContainer {
  display: flex;
  height: 100%;
  &__input {
    font-size: inherit;
    &::placeholder {
      font-size: inherit;
      text-transform: uppercase;
      font-family: 'Helvetica';
      font-style: normal;
      font-weight: 400;
      /* @include font-size(16px); */
      line-height: 18px;
    }
  }
  &__button {
    font-size: inherit;
    height: 100%;
    background: #222c64;
    border: #222c64;
    svg {
      fill: $secondary-color;
    }
  }
}

/* .input {
  height: 100%;
  :global {
    .ant-input-wrapper {
      height: 100%;
    }
  }
  input {
    height: 100%;
    &::placeholder {
      text-transform: uppercase;
      font-family: 'Helvetica';
      font-style: normal;
      font-weight: 400;
      @include font-size(16px);
      line-height: 18px;
    }
  }
  > span {
    display: inline-block;
    width: 100%;
  }
  button {
    height: 100% !important;
    width: 52px;
    background: #222c64;
    border: #222c64;
    svg {
      fill: $secondary-color;
    }
  }
} */
