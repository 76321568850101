@import 'css/variables';
@import 'css/mixins';

.container {
  //Replace background color of entire sidebar container
  background: $background-color-sidebar !important;
  &.--notCollapsed {
    max-width: pxToRem(200px) !important;
    min-width: pxToRem(200px) !important;
    :global {
      .ant-layout-sider-trigger {
        max-width: pxToRem(200px) !important;
        min-width: pxToRem(200px) !important;
      }
    }
  }
  max-width: pxToRem(100px) !important;
  min-width: pxToRem(100px) !important;

  // Replace background color of trigger container
  :global {
    .ant-layout-sider-children {
      overflow: auto;
    }
    .ant-layout-sider-trigger {
      background: $background-color-sidebar;
      max-width: pxToRem(100px) !important;
      min-width: pxToRem(100px) !important;
    }
  }

  &__logoContainer {
    margin-top: pxToRem(30px);
    margin-bottom: pxToRem(45px);
    height: pxToRem(40px);
    width: 100%;
    display: flex;
    justify-content: center;

    &__logo {
      width: pxToRem(91px);
    }
  }

  &__menu {
    // Replace background color of menu container
    height: fit-content !important;

    background: $background-color-sidebar !important;
    ul {
      background: $background-color-sidebar !important;
    }
    &__item {
      font-family: 'Barlow Condensed';
      font-style: normal;
      font-weight: 500;
      line-height: pxToRem(19px);

      margin: 0 !important;
      height: pxToRem(50px) !important;

      div {
        height: 100% !important;
        margin: 0 !important;
      }

      span {
        @include font-size(16px);
        color: $white;
        text-transform: uppercase;
      }
      &__icon {
        display: inline-flex;

        svg {
          @include font-size(19px);
        }
      }
    }

    // Replace active item styles
    :global {
      .ant-menu-item-selected {
        background: transparent !important;
        span {
          color: $secondary-color !important;
        }
      }
    }
  }
}
