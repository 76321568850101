@import 'css/general_styling';

.title {
  background: $secondary-color;
  padding: 1.3em 1.5em;
  span {
    font-family: 'Barlow Condensed';
    font-style: normal;
    font-weight: 500;
    @include font-size(20px);
    line-height: 24px;
    color: $primary-color;
  }
}

.data {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 1.5em 1.5em;

  &__item {
    display: flex;
    flex-direction: column;
    row-gap: 0.2em;
    p {
      margin: 0;
    }

    &__title {
      font-family: 'Barlow Condensed';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      /* identical to box height */

      color: #83838a;
    }
  }
}
