@import 'css/general_styling';
@import 'css/mixins';

.container {
  &__separator {
    color: white;
  }
  &__item {
    @include label($label, $white);
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    @include font-size(14px);
    line-height: pxToRem(16px);
    text-transform: uppercase;

    color: #f2f2f2 !important;
    cursor: default;

    &.--link {
      cursor: pointer;
    }
    &.--noLink {
      color: #f2f2f2a6 !important;
    }
  }

  margin-bottom: pxToRem(5px);

  .ant-breadcrumb-link {
    color: $white;
  }
}
