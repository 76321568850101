.container {
  &.--centered {
    display: flex;
    justify-content: center;
  }
}

.loadingSpinner {
  color: rgb(34, 44, 100) !important;
  .anticon svg {
    width: 24px;
    height: 24px;
  }
  span {
    font-size: 24px !important;
  }
}
