@import './functions';
@import './variables';

/* FONTS */

@mixin font-size($sizeValue) {
  font-size: $sizeValue;
  font-size: pxToRem($sizeValue);
}

/* RESPONSIVE */

// Smartphones (portrait view)
$breakpoint-xs: 480px;

// Tablets (portrait view)
$breakpoint-sm: 768px;

// Small desktops
$breakpoint-md: 1280px;

// Large desktops
$breakpoint-lg: 1440px;

@mixin media($media) {
  @if $media ==xs {
    @media (max-width: $breakpoint-xs) {
      @content;
    }
  } @else if $media ==sm {
    @media (min-width: calc($breakpoint-xs + 1px)) and (max-width: $breakpoint-sm) {
      @content;
    }
  } @else if $media ==md {
    @media (min-width: calc($breakpoint-sm + 1px)) and (max-width: $breakpoint-md) {
      @content;
    }
  } @else if $media ==lg {
    @media (min-width: calc($breakpoint-md + 1px)) and (max-width: $breakpoint-lg) {
      @content;
    }
  } @else if $media ==xl {
    @media (min-width: calc($breakpoint-lg + 1px)) {
      @content;
    }
  } @else if $media ==up2xs {
    @media (min-width: calc($breakpoint-xs + 1px)) {
      @content;
    }
  } @else if $media ==up2sm {
    @media (min-width: calc($breakpoint-sm + 1px)) {
      @content;
    }
  } @else if $media ==up2md {
    @media (min-width: calc($breakpoint-md + 1px)) {
      @content;
    }
  } @else if $media ==up2lg {
    @media (min-width: calc($breakpoint-md + 1px)) {
      @content;
    }
  }
}

/* @mixin primary-title($font-size: $title-main, $font-color: $secondary-color) {
  margin: 0;
  font-family: 'Barlow Condensed', sans-serif;
  @include font_size($font-size);
  font-style: normal;
  font-weight: 700;
  line-height: 72px;
  text-transform: uppercase;
  color: $font-color;
} */

@mixin padding-inline($value) {
  padding-left: $value;
  padding-right: $value;
  padding-inline: $value;
}

@mixin padding-block($value) {
  padding-top: $value;
  padding-bottom: $value;
  padding-block: $value;
}

@mixin margin-inline($value) {
  margin-left: $value;
  margin-right: $value;
  margin-inline: $value;
}

@mixin margin-block($value) {
  margin-top: $value;
  margin-bottom: $value;
  margin-block: $value;
}
