// Colors

$background-grey: #fdfdfe;
$background-pending: #fffaf0;
$background-approved: #f3fbfa;
$background-color: #18213c;
$background-color-login: rgba(0, 25, 75, 1);
$background-color-dashboard: rgba(26, 51, 121, 1);
$background-color-sidebar: rgba(24, 33, 60, 1);
$primary-color: rgba(25, 51, 122, 1); /* #19337a */
$primary-color-disabled: #727baa;
$secondary-color: rgba(217, 195, 121, 1); /* d9c379 */
$ternary-color: #2e3190;

$warning: #f1af34;
$error-color: rgba(255, 54, 54, 1);
$danger-color: #ff4d4f;
$danger-color-dark: #b70505;
$success-color: #52c41a;
$alert-color: #1890ff;
$idle-color: #f2994a;
$processing-color: #d6d43b;

$light-grey: #f3f3f3;

$medium-grey: #6d6d6d;
$dark-grey: #242424;

$white: #ffff;
$black: black;
$opaque-black: rgba(0, 0, 0, 0.45);

$border_bottom: 1px solid $light-grey;

// Typographic Scale
$title-main: 60px;
$title-secondary: 18px;
$title-terciary: 16px;
$body: 14px;
$label: 12px;
$description: 10px;
$subtitle: 14px;

// Heights
$header-height: pxToRem(100px);

$label-black: #00194b;
$card-border: rgba(0, 25, 75, 0.3);
$header-background: #f1f1f1;
$text-blue: #011747;
$text-blue-light: #1a3379;
$character-title-85: rgba(0, 0, 0, 0.85);
$link-blue: #5585ff;
$character-disabled-amp-placeholder-25: rgba(0, 0, 0, 0.25);
$tag-background-gray: #c9cbd0;
$tag-text-black: #272727;
