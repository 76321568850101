@import 'css/variables';
@import 'css/mixins';
@import 'css/functions';

.container {
  display: flex;
  flex-direction: column;

  &__label {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    @include font-size(14px);
    line-height: pxToRem(22px);
    color: rgba(0, 0, 0, 0.85);
    margin-bottom: pxToRem(8px);
  }

  &__input {
    font-size: inherit;
    font-style: normal;
    font-weight: 400;
    @include padding-inline(var(--paddingInline));
    @include padding-block(var(--paddingBlock));

    input {
      font-size: inherit;
      &::placeholder {
        font-size: inherit;
      }
    }

    &.--password {
      input {
        background: transparent !important;
        font-family: 'Helvetica';
        color: $secondary-color;

        &::placeholder {
          color: rgba($secondary-color, 0.5);
        }
        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
          box-shadow: 0 0 0px 1000px $background-color-login inset !important;
          -webkit-box-shadow: 0 0 0px 1000px $background-color-login inset !important;
          -webkit-text-fill-color: $secondary-color !important;
        }
      }
      span {
        color: $secondary-color;
        &:hover {
          color: $secondary-color;
        }
      }
    }

    &.--primary {
      box-shadow: 0 0 0 pxToRem(30px) transparent;
      background: transparent !important;
      font-family: 'Helvetica';
      color: $secondary-color;
      border: solid 2px $secondary-color;

      &::placeholder {
        color: rgba($secondary-color, 0.5);
      }
    }
    &.--default {
      line-height: pxToRem(24px);
      font-family: 'Roboto';

      border: solid 1px rgba(217, 217, 217, 1);
      @include padding-inline(0.8em);

      &::placeholder {
        color: rgba(rgba(0, 0, 0), 0.3);
      }
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-transition-delay: 9999s;
      transition-delay: 9999s;
    }
  }
  &__ruleAnnotation {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    @include font-size(14px);
    line-height: pxToRem(22px);

    color: rgba(0, 0, 0, 0.45);
  }
}
