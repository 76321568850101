@import 'css/mixins';
@import 'css/mixins/typography.scss';
@import 'css/general_styling';
@import 'css/variables';

.table {
  height: 100%;
  .bodyRow {
    @include tertiary-text(0.875rem, $black, 400, 1.375rem);
    color: #505050;
    background: #f4f4f4;

    &.--white {
      background: white;
    }

    td {
      padding: pxToRem(12px) !important;
    }
  }

  .headerRow {
    th {
      @include secondary-text(14px, $black, 500, 22px);
      padding: pxToRem(12px) !important;
      background: #d9c379 !important;
      color: #19337a !important;
      text-transform: uppercase;
      word-wrap: normal;
    }
    &.--white {
      th {
        background: white !important;
      }
    }
  }

  .pagination {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 1.6em;

    * {
      @include font-size(15.5px);
    }

    :global {
      li.ant-pagination-options {
        div {
          margin-right: 0 !important;
        }
      }

      li.ant-pagination-item-active {
        a {
          color: $primary-color;
        }

        border-color: $primary-color;
      }
    }
  }
}
