@import 'css/mixins';
@import 'css/functions';

.selector {
  font-size: inherit;
  height: 100%;
  :global {
    .ant-select-selector {
      height: 100% !important;
      align-items: center;
      font-size: inherit;
      overflow: hidden;
      display: flex;
      align-content: center;

      .ant-select-selection-placeholder {
        padding-right: 10px;
        font-size: inherit;
        font-weight: 400;
        line-height: pxToRem(18px);
        font-family: 'Helvetica';
      }
    }

    .ant-select-selection-search-input {
      height: auto !important;
      line-height: 37px;
    }
  }

  &.--uppercasePlaceholder {
    :global {
      .ant-select-selector {
        .ant-select-selection-placeholder {
          text-transform: uppercase;
        }
      }
    }
  }

  &.--noSuffixIcon {
    :global {
      .ant-select-selector {
        .ant-select-selection-overflow {
          .ant-select-selection-overflow-item-suffix {
            display: none;
          }
        }
      }
    }
  }
}