@import 'css/variables';
@import 'css/mixins';

.button {
  white-space: nowrap;
  cursor: pointer;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  @include font-size(14px);
  min-height: fit-content;
  min-width: fit-content;
  position: relative;
  text-transform: uppercase;
  line-height: normal;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &.--primary {
    &:disabled {
      background-color: rgba($secondary-color, 0.6);
      border: none;
    }
  }
  &.--link {
    padding: 0;
  }

  &.--inheritColor {
    color: inherit !important;
  }
}
