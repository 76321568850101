@import 'css/variables';
@import 'css/mixins';
@import 'css/functions';

.form {
  display: grid;
  grid-template-rows: auto pxToRem(22px) auto pxToRem(30px) auto pxToRem(50px) auto;

  &__userFormItem {
    grid-row: 1/2;

    &__input {
      @include font-size(15px);
    }
  }
  &__passwordFormItem {
    grid-row: 3/4;

    &__input {
      @include font-size(15px);
    }
  }
  &__submitButtonContainer {
    grid-row: 5/6;
    &__button {
      width: pxToRem(187px);
      height: pxToRem(45px);
    }
    &__button_reset {
      width: pxToRem(187px);
      height: pxToRem(45px);
      border: none;
    }
  }
  &__errorMessagesContainer {
    grid-row: 7/8;
    color: $error-color;
    position: relative;

    &__errorMessages {
      position: absolute;
      display: flex;
      flex-direction: column;
      row-gap: pxToRem(10px);

      &__errorMessage {
        display: flex;
        column-gap: pxToRem(11px);
        align-items: center;

        &__icon,
        &__text {
          display: inline-block;
          margin: 0;
          @include font-size(25px);
        }

        &__text {
          @include font-size(16px);
          text-transform: uppercase;
        }

        &__icon {
          width: pxToRem(25px);
        }
      }
    }
  }
}
