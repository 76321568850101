@import 'css/general_styling';
@import 'css/functions';
.container {
  display: flex;
  flex-direction: column;

  &__content {
    margin: pxToRem(22px) 0 pxToRem(24px) 0;
    background: $white;
    padding: 1em;
  }
  &__buttonContainer {
    display: flex;
    justify-content: flex-end;
    column-gap: 0.9em;
    padding-bottom: 1.2em;

    &__button {
      width: pxToRem(124px);
      height: pxToRem(48px);
    }
  }
}
