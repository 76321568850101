.itemDetail {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 5px;
}

.totalItemDetail {
  display: flex;
  justify-content: space-between;
  gap: 5px;
}
