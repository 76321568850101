.customFormItem {
  margin-bottom: 0;
  &.--hideError {
    :global {
      .ant-form-item-explain {
        display: none;
      }
    }
  }
  :global {
    .ant-form-item-control {
      font-size: inherit;
      .ant-form-item-explain {
        font-size: inherit;
        min-height: fit-content;
      }
    }
  }
}
