@import 'css/_variables.scss';
@import 'css/mixins/typography.scss';

.uploadFormItemContainer {
  :global {
    .ant-form-item-has-error {
      .ant-upload-select-picture-card {
        border: solid $danger-color thin;
      }
      .ant-upload-list-picture-card-container {
        border: solid $danger-color thin;
      }
    }
  }
  &__formItem {
    label {
      &::before {
        align-self: start;
      }
    }
    &__labelContainer {
      @include tertiary-text(0.875rem, $black, 400, 1.375rem);
      &__fieldName {
        font-weight: 500;
        margin: 0;
      }
      &__rulesContainer {
        p {
          margin: 0;
        }
      }
    }
  }
}

.modalContainer {
  :global {
    .ant-modal-content {
      background: transparent;
      box-shadow: none;
    }
    .ant-modal-close {
      color: $white;
      right: -1rem;
      top: -0.5rem;
    }
  }
}
