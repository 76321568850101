@import 'css/variables';
@import 'css/mixins';

.container {
  height: $header-height;
  background: linear-gradient(180deg, #00194b 50%, rgba(0, 25, 75, 0) 100%) !important;
  color: $white;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  &__content {
    &__name {
      font-family: 'Barlow Condensed';
      font-style: normal;
      font-weight: 500;
      @include font-size(14px);
      line-height: pxToRem(18px);
      text-align: right;
      color: $white;
      cursor: default;
    }

    &__separator {
      border-left: pxToRem(1px) solid #8a96a0;
      height: pxToRem(29px);
      @include margin-inline(18px);
    }

    &__icons {
      @include font-size(17px);
      display: flex;
      column-gap: pxToRem(22px);
      align-items: center;

      svg {
        fill: $white !important;
      }
    }
  }
}
