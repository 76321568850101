@import 'css/variables.scss';

.privateRoutesContent {
  height: 100vh;
  max-height: 100vh;
  /*   overflow: auto;
  background: $background-color-dashboard; */

  &__main {
    background: $background-color-dashboard !important;
    overflow: auto;
  }
}

.publicRoutesContent {
  background: $background-color-login;
  height: 100vh;
  max-height: 100vh;
  overflow: auto;
}
