@import 'css/variables';
@import 'css/mixins';

.container {
  margin-inline: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 1rem;

  &__text {
    width: 3.6875rem;
    text-align: start;
  }
  &__text__auto {
    width: auto;
    text-align: start;
  }

  &__copyButton {
    color: $medium-grey;
    width: fit-content;
    span {
      @include font-size(16px);
    }
  }
}
