@import 'css/mixins';

.rangePicker,
.inputSearchContainer,
.serieSelector,
.serieOption,
.orderStateSelector,
.orderStateOption,
.activationSelector,
.activationOption,
.originSelector,
.originOption {
  @include font-size(16px);
}

.rangePicker,
.inputSearchContainer {
  width: 20%;
}

.serieSelector,
.orderStateSelector,
.activationSelector,
.originSelector {
  width: 15%;
}
