@import './mixins';

html {
  font-size: 16px;
}

// Colors

$background-grey: #fdfdfe;
$background-pending: #fffaf0;
$background-approved: #f3fbfa;
$background-color: #18213c;
$background-color-login: #00194b;
$background-color-dashboard: #1a3379;

$primary-color: #222c64;
$primary-color-disabled: #727baa;
$secondary-color: #d9c379;
$ternary-color: #2e3190;

$warning: #f1af34;
$error: #db2e27;

$light-grey: #f3f3f3;
$medium-grey: #6d6d6d;
$dark-grey: #242424;

$white: #ffff;
$black: black;

$border_bottom: 1px solid $light-grey;

// Typographic Scale
$title-main: 60px;
$title-secondary: 18px;
$title-terciary: 16px;
$body: 14px;
$label: 12px;
$description: 10px;
$subtitle: 14px;

@mixin label($font-size, $font-color) {
  margin: 0;
  font-family: 'barlow-medium';
  font-size: $font-size;

  @if $font-color {
    color: $font-color;
  }
}

// Container

@mixin container($color, $border, $radius) {
  background: #3d3737;
  border: 1px solid black;
  border-radius: $radius;
}

// Font Family

@font-face {
  font-family: 'barlow-regular';
  src: url(./fonts/Barlow-Regular.ttf);
}

@font-face {
  font-family: 'barlow-semibold';
  src: url(./fonts/Barlow-SemiBold.ttf);
}

@font-face {
  font-family: 'barlow-bold';
  src: url(./fonts/Barlow-Bold.ttf);
}

@font-face {
  font-family: 'barlow-medium';
  src: url(./fonts/Barlow-Medium.ttf);
}

//Antd

// buttons
.disabled {
  background: #bcbcbc;

  p {
    color: #515151;
  }
}

button:focus {
  outline: transparent;
}

.ant-picker-today-btn,
.ant-picker-cell-in-view.ant-picker-cell-today,
.ant-picker-cell-inner::before {
  color: $primary-color;
}

.ant-message-custom-content {
  justify-content: center;
  align-items: center;
  display: flex;
}
