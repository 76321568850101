@mixin primary-text($font-size, $font-color: $black, $font-weight: 400, $line-height: 40px) {
  font-family: 'Barlow Condensed', sans-serif;
  color: $font-color;
  font-size: pxToRem($font-size);
  font-weight: $font-weight;
  line-height: pxToRem($line-height);
}

@mixin secondary-text($font-size, $font-color: black, $font-weight: 400, $line-height: 40px) {
  font-family: 'Barlow', sans-serif;
  color: $font-color;
  font-size: pxToRem($font-size);
  font-weight: $font-weight;
  line-height: pxToRem($line-height);
}

@mixin tertiary-text($font-size, $font-color: black, $font-weight: 400, $line-height: normal) {
  font-family: 'Roboto', sans-serif;
  color: $font-color;
  font-size: $font-size;
  font-weight: $font-weight;
  line-height: $line-height;
}

@mixin quaternary-text($font-size, $font-color: black, $font-weight: 400, $line-height: 40px) {
  font-family: 'Poppins', sans-serif;
  color: $font-color;
  font-size: pxToRem($font-size);
  font-weight: $font-weight;
  line-height: pxToRem($line-height);
}
