@import 'css/variables.scss';

.ImgContainer {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  row-gap: 1.5rem;
}
.button {
  border: 2px solid $secondary-color !important;
  color: $secondary-color !important;
  border-radius: 90px;
  background: $background-color-login;
  height: 30px;
  padding-bottom: 0px;
  a {
    padding-bottom: 0px;
  }
}
.ErrorPage {
  background: $background-color-login;
  height: 100vh;
  max-height: 100vh;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $secondary-color;
  font-family: 'Barlow Condensed';
  text-align: center;

  h1 {
    color: $secondary-color;
    font-style: normal;
    font-weight: 700;
    font-size: 60px;
    line-height: 72px;
  }
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 34px;
    text-transform: uppercase;
  }
}
.logo {
  width: 100%;
}
