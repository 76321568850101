@import 'css/mixins/typography';
@import 'css/functions';
@import 'css/variables';

.login-image img {
  width: 80%;
}

.error {
  text-align: right;
  color: #e20000;
  font-family: 'poppins-medium';
  padding: pxToRem(5px);
}

.login-form-container {
  width: 60%;
  padding: pxToRem(100px);
  padding-left: pxToRem(280px);
  justify-content: center;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
}

.login-title {
  margin-bottom: pxToRem(20px);
}

/*  */

.container {
  width: 100%;
  height: 100%;
  padding-left: 18.2%;
  padding-right: 2.9%;
  display: grid;
  grid-template-columns: auto 37.8%;
  grid-template-rows: 1fr;
  align-items: center;
  grid-column-gap: pxToRem(55px);

  &__login {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto pxToRem(32px) auto pxToRem(40px) auto;

    &__logoContainer {
      grid-row: 1/2;
      width: pxToRem(140px);

      &__logo {
        object-fit: contain;
        height: 100%;
        width: 100%;
      }
    }

    &__submitButtonContainer {
      grid-row-start: 3;
      grid-row-end: 4;
      &__button {
        width: pxToRem(187px);
        height: pxToRem(45px);
        margin-top: 3%;
      }
    }

    &__title_reset_password {
      grid-row-start: 3;
      grid-row-end: 4;
      @include primary-text(60px, $secondary-color, 700, 72px);
      margin-bottom: 0;
    }

    &__title {
      grid-row-start: 3;
      grid-row-end: 4;
      @include primary-text(60px, $secondary-color, 700, 72px);
    }

    &__title2 {
      grid-row-start: 3;
      grid-row-end: 4;
      font-family: 'Barlow Condensed';
      font-style: normal;
      font-weight: 400;
      font-size: 28px;
      line-height: 34px;
      text-transform: uppercase;
      color: #d9c379;
    }

    &__form {
      grid-row: 5/6;
    }
  }

  &__messiImageContainer {
    /* width: 56.8%; */
    height: pxToRem(860px);
    max-height: 80%;
    /* border: solid red; */

    &__image {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
