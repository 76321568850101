@import 'css/mixins/typography';
@import 'css/functions';
@import 'css/variables';
@import 'css/mixins';

.container {
  width: 100%;
  height: 100%;
  padding-left: 18.2%;
  padding-right: 2.9%;
  display: grid;
  grid-template-columns: auto 37.8%;
  grid-template-rows: 1fr;
  align-items: center;
  grid-column-gap: pxToRem(55px);

  &__login {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto pxToRem(32px) auto pxToRem(40px) auto;

    &__userFormItem {
      grid-row: 1/2;

      &__input {
        @include font-size(15px);
      }
    }
    &__passwordFormItem {
      grid-row: 3/4;
      margin-bottom: 2%;

      &__input {
        @include font-size(15px);
        margin-bottom: 2%;
      }
    }

    &__logoContainer {
      grid-row: 1/2;
      width: pxToRem(140px);

      &__logo {
        object-fit: contain;
        height: 100%;
        width: 100%;
      }
    }

    &__submitButtonContainer {
      grid-row-start: 3;
      grid-row-end: 4;
      &__button {
        width: pxToRem(187px);
        height: pxToRem(45px);
        margin-top: 3%;
      }
    }

    &__title_reset_password {
      grid-row-start: 3;
      grid-row-end: 4;
      @include primary-text(60px, $secondary-color, 700, 72px);
      margin-bottom: 0;
    }

    &__title {
      grid-row-start: 3;
      grid-row-end: 4;
      @include primary-text(60px, $secondary-color, 700, 72px);
    }

    &__title2 {
      grid-row-start: 3;
      grid-row-end: 4;
      font-family: 'Barlow Condensed';
      font-style: normal;
      font-weight: 400;
      font-size: 28px;
      line-height: 34px;
      text-transform: uppercase;
      color: #d9c379;
    }

    &__form {
      grid-row: 5/6;
    }
  }
}

.form {
  grid-template-rows: auto pxToRem(22px) auto pxToRem(30px) auto pxToRem(50px) auto;

  &__userFormItem {
    grid-row: 1/2;

    &__input {
      @include font-size(15px);
    }
  }
  &__passwordFormItem {
    grid-row: 3/4;

    &__input {
      @include font-size(15px);
    }
  }
  &__submitButtonContainer {
    grid-row: 5/6;
    &__button {
      width: pxToRem(187px);
      height: pxToRem(45px);
    }
    &__button_reset {
      width: pxToRem(187px);
      height: pxToRem(45px);
      border: none;
    }
  }
  &__errorMessagesContainer {
    grid-row: 7/8;
    color: $error-color;
    position: relative;

    &__errorMessages {
      position: absolute;
      display: flex;
      flex-direction: column;
      row-gap: pxToRem(10px);

      &__errorMessage {
        display: flex;
        column-gap: pxToRem(11px);
        align-items: center;

        &__icon,
        &__text {
          display: inline-block;
          margin: 0;
          @include font-size(25px);
        }

        &__text {
          @include font-size(16px);
          text-transform: uppercase;
        }

        &__icon {
          width: pxToRem(25px);
        }
      }
    }
  }
}
