@import './css/general_styling';
@import './css/mixins';

html {
  font-size: 10px;
  @media only screen and (min-width: 1920px) {
    font-size: 16px;
  }
  @media only screen and (min-width: 1536px) and (max-width: 1919px) {
    font-size: 15px;
  }
  @media only screen and (min-width: 1440px) and (max-width: 1535px) {
    font-size: 14px;
  }
  @media only screen and (min-width: 1366px) and (max-width: 1439px) {
    font-size: 13px;
  }
  @media only screen and (min-width: 1280px) and (max-width: 1365px) {
    font-size: 12px;
  }
  @media only screen and (min-width: 1024px) and (max-width: 1279px) {
    font-size: 11px;
  }
}
