.container {
  background: #f4f4f4;
  /* Body/regular */

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  color: #505050;

  padding: 1.8em 1.2em;
}
