@import 'css/mixins';

.container {
  * {
    font-size: inherit;
  }
  input {
    text-transform: uppercase;
    font-size: inherit;
  }
  :global {
    .ant-picker-range-separator {
      .ant-picker-separator {
        display: flex;
        align-items: center;
      }
    }
  }
}
