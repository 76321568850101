@import 'css/mixins';
@import 'css/variables';

.tabs {
  &.--fullWidthTabs {
    :global {
      .ant-tabs-nav-wrap {
        .ant-tabs-nav-list {
          width: 100%;
          flex-wrap: wrap;
        }
      }

      .ant-tabs-nav-operations {
        display: none !important;
      }
    }
  }

  &.--adjustWidthTabs {
    :global {
      .ant-tabs-nav-wrap {
        .ant-tabs-nav-list {
          width: auto;
          flex-wrap: wrap;

          .ant-tabs-tab {
            padding: 0 16px;
          }
        }
      }

      .ant-tabs-nav-operations {
        display: none !important;
      }
    }
  }


  :global {
    .ant-tabs-nav-wrap {
      .ant-tabs-nav-list {
        .ant-tabs-tab {
          flex-grow: 1;
          border: none;
          border-radius: 0;
          color: $primary-color !important;
          padding-top: 1em !important;
          padding-bottom: 1em !important;
          background: #ededed;
          display: flex;
          justify-content: center;

          @include font-size(14px);
          font-family: 'Barlow';
          -webkit-border-radius: 0;
          -moz-border-radius: 0;
          -ms-border-radius: 0;
          -o-border-radius: 0;
        }

        .ant-tabs-tab-active {
          background: $secondary-color !important;

          div {
            font-weight: 700;
            color: $primary-color;
          }
        }
      }
    }
  }

  &.--lineType {
    :global {
      .ant-tabs-nav-wrap {
        .ant-tabs-nav-list {
          .ant-tabs-tab {
            background-color: transparent !important;
          }

          .ant-tabs-tab-active {
            background: transparent !important;
          }
        }
      }
    }
  }
}