@import 'css/variables';
@import 'css/mixins';
@import 'css/functions';

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: $primary-color;
  row-gap: pxToRem(20px);

  &__icon {
    color: red;
    @include font-size(40px);
  }
  &__message {
    text-align: center;
  }

  h1 {
    font-weight: 800;
  }

  h1,
  h2 {
    color: $secondary-color;
  }
}
